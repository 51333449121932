import * as React from 'react';
import { useTranslation } from 'react-i18next';

type ToolHelpProps = {
  pageTitle: string;
};

const ToolHelp: React.VoidFunctionComponent<ToolHelpProps> = ({ pageTitle }) => {
  const { t } = useTranslation('tools');

  const emailRecipient: string = t('help.email');
  function EmailRecipient(): string {
    const param = encodeURI(`?subject=${t('help.email-subject', { pageTitle })}`);
    return emailRecipient + param;
  }

  return (
    <div className="mb-9 text-center text-sm font-medium text-black sm:text-base">
      {t('help.contact')}{' '}
      <a
        href={`mailto:${EmailRecipient()}`}
        title={t('help.email-link-title')}
        className="underline"
        target="_blank"
        rel="noreferrer"
      >
        {t('help.email-name')}
      </a>
    </div>
  );
};

export default ToolHelp;
