import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../layouts/default-layout';
import { PageContextProps } from '../../utils/graphql';
import { WHITE_ON_BLACK, WHITE_ON_BLACK_PSEUDO_CLASS } from '../Colors';
import ToolHelp from './toolHelp';
import { LocalizedLink } from 'gatsby-theme-i18n';

type ToolPageProps = {
  toolId: string;
  pageContext: PageContextProps;
};

const ToolPage: React.VoidFunctionComponent<ToolPageProps> = ({ toolId, pageContext }) => {
  const { t } = useTranslation('tools');

  const pageTitle = t(`tools.${toolId}.head-title`);

  const externalUrlKey = `tools.${toolId}.externalUrl`;
  const externalUrl = t(externalUrlKey);

  const linkClassName = `${WHITE_ON_BLACK} ${WHITE_ON_BLACK_PSEUDO_CLASS} my-5 mx-auto inline-block rounded-1 border border-solid border-black py-4 px-8 text-center font-semibold leading-5 no-underline outline-none sm:my-9`;

  return (
    <DefaultLayout
      headTitle={pageTitle}
      headDescription={t(`tools.${toolId}.head-description`)}
      lang={pageContext.locale}
      wrapInContainer
    >
      <div className="lg:px-8 xl:px-12">
        <h1 id={toolId} className="mb-9 text-black">
          {t(`tools.${toolId}.header-title`)}
        </h1>
        <div className="mb-2 text-center text-sm font-medium text-black sm:text-base">
          {t(`tools.${toolId}.header-subtitle`)}
        </div>
        <div className="whitespace-pre-line text-center text-base font-medium text-black sm:text-2xl">
          {t(`tools.${toolId}.long-description`)}
        </div>
        <div className="text-center">
          {externalUrl === externalUrlKey ? (
            <LocalizedLink
              className={linkClassName}
              to={`/tools/${toolId}/animate/`}
              title={t('start')}
            >
              {t('start')}
            </LocalizedLink>
          ) : (
            <a
              className={linkClassName}
              href={externalUrl}
              title={t('start')}
              target="_blank"
              rel="noreferrer"
            >
              {t('start')}
            </a>
          )}
          <ToolHelp pageTitle={pageTitle} />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ToolPage;
